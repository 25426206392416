import React, { useState } from 'react'
import {
  Typography,
  Card,
  Row,
  Col,
  Button,
  Carousel,
  Divider,
  Modal,
} from 'antd'
import './styles.css' // CSS file for styling
import AppCard from '../../components/Card'
import { Link } from 'react-router-dom'

import mainCarouselImage1 from '../../assets/images/pages/charity/main-carousel/1.jpeg'
import mainCarouselImage2 from '../../assets/images/pages/charity/main-carousel/2.jpeg'
import mainCarouselImage3 from '../../assets/images/pages/charity/main-carousel/3.jpeg'
import mainCarouselImage4 from '../../assets/images/pages/charity/main-carousel/4.jpeg'
import mainCarouselImage5 from '../../assets/images/pages/charity/main-carousel/5.jpeg'
import mainCarouselImage6 from '../../assets/images/pages/charity/main-carousel/6.jpeg'

import feedingWidowsCarouselImage1 from '../../assets/images/pages/charity/success-stories/feeding-widows/1.jpeg'
import feedingWidowsCarouselImage2 from '../../assets/images/pages/charity/success-stories/feeding-widows/2.jpeg'
import feedingWidowsCarouselImage3 from '../../assets/images/pages/charity/success-stories/feeding-widows/3.jpeg'

import buildingBridgeCarouselImage1 from '../../assets/images/pages/charity/success-stories/building-bridge/1.jpeg'
import buildingBridgeCarouselImage2 from '../../assets/images/pages/charity/success-stories/building-bridge/2.jpeg'
import buildingBridgeCarouselImage3 from '../../assets/images/pages/charity/success-stories/building-bridge/3.jpeg'

import aceOfClubsImage1 from '../../assets/images/pages/charity/success-stories/ace-of-clubs/1.jpeg'
import aceOfClubsImage2 from '../../assets/images/pages/charity/success-stories/ace-of-clubs/2.jpeg'
import aceOfClubsImage3 from '../../assets/images/pages/charity/success-stories/ace-of-clubs/3.jpeg'

import britishHomeImage1 from '../../assets/images/pages/charity/success-stories/british-home/1.jpeg'
import britishHomeImage2 from '../../assets/images/pages/charity/success-stories/british-home/2.jpeg'
import britishHomeImage3 from '../../assets/images/pages/charity/success-stories/british-home/3.jpeg'

import britishHomeChristmasImage1 from '../../assets/images/pages/charity/success-stories/british-home-christmas/1.jpeg'
import britishHomeChristmasImage2 from '../../assets/images/pages/charity/success-stories/british-home-christmas/2.jpeg'
import britishHomeChristmasImage3 from '../../assets/images/pages/charity/success-stories/british-home-christmas/3.jpeg'

import brixtonMarketImage1 from '../../assets/images/pages/charity/success-stories/brixton-market/1.jpeg'
import brixtonMarketImage2 from '../../assets/images/pages/charity/success-stories/brixton-market/2.jpeg'
import brixtonMarketImage3 from '../../assets/images/pages/charity/success-stories/brixton-market/3.jpeg'
import brixtonMarketImage4 from '../../assets/images/pages/charity/success-stories/brixton-market/4.jpeg'
import brixtonMarketImage5 from '../../assets/images/pages/charity/success-stories/brixton-market/5.jpeg'
import brixtonMarketImage6 from '../../assets/images/pages/charity/success-stories/brixton-market/6.jpeg'
import brixtonMarketImage7 from '../../assets/images/pages/charity/success-stories/brixton-market/7.jpeg'
import brixtonMarketImage8 from '../../assets/images/pages/charity/success-stories/brixton-market/8.jpeg'
import brixtonMarketImage9 from '../../assets/images/pages/charity/success-stories/brixton-market/9.jpeg'
import brixtonMarketImage10 from '../../assets/images/pages/charity/success-stories/brixton-market/10.jpeg'

import stChristophersImage1 from '../../assets/images/pages/charity/success-stories/st-christophers-hospice/1.jpeg'
import stChristophersImage2 from '../../assets/images/pages/charity/success-stories/st-christophers-hospice/2.jpeg'

import sundayFeedingImage1 from '../../assets/images/pages/charity/success-stories/sunday-feeding/1.jpeg'

const { Title, Paragraph, Text } = Typography

const mainCarouselImages = [
  mainCarouselImage5,
  britishHomeImage1,
  brixtonMarketImage2,
  mainCarouselImage3,
  mainCarouselImage2,
  brixtonMarketImage7,
  mainCarouselImage6,
  mainCarouselImage1,
]

const successStoryCardContents = [
  {
    title: 'Ace of Clubs Support',
    description: 'TMCO Charity Founder Prophetess Teleola Ladipo Oganla and Sarah Miles, Centre Manager Ace of Clubs, collaborated on charity outreach in the UK. Ace of Clubs is a vital day centre in Clapham for homeless and vulnerable people, providing essential services including hot meals, clothing, laundry, showers, healthcare, housing & welfare support, and computer access. The centre operates weekdays from 9:30 - 16:30, offering a welcoming environment where anyone can access help without referral.',
    cardImageBackground: aceOfClubsImage1,
    modalCarouselImages: [
      aceOfClubsImage1,
      aceOfClubsImage2,
      aceOfClubsImage3,
    ],
  },
  {
    title: 'British Home',
    description: 'British Home is a therapeutic community for individuals living with neuro-disabilities. They provide essential services including slow-stream rehabilitation, intensive neurorehabilitation, nursing support, respite care, and palliative care. TMCO Charity regularly visits and supports the residents at British Home, helping to enhance their quality of life.',
    cardImageBackground: britishHomeImage1,
    modalCarouselImages: [
      britishHomeImage1,
      britishHomeImage2,
      britishHomeImage3,
    ],
  },
  {
    title: 'British Home Christmas',
    description: 'TMCO Charity brings festive cheer to British Home during Christmas celebrations. British Home accommodates up to 80 residents with neuro-disabilities, each with their own private living space. Our charity helps create a warm and joyful atmosphere during the holiday season for these residents who are recovering from accidents, strokes, trauma, or living with degenerative conditions.',
    cardImageBackground: britishHomeChristmasImage1,
    modalCarouselImages: [
      britishHomeChristmasImage1,
      britishHomeChristmasImage2,
      britishHomeChristmasImage3,
    ],
  },
  {
    title: 'Brixton Market Initiative',
    description: 'TELEOLA MARTHA FOUNDATION on a charitable feeding of the less privileged and the needy in Brixton Market on 1st of October 2016.',
    cardImageBackground: brixtonMarketImage1,
    modalCarouselImages: [
      brixtonMarketImage1,
      brixtonMarketImage2,
      brixtonMarketImage3,
      brixtonMarketImage4,
      brixtonMarketImage5,
      brixtonMarketImage6,
      brixtonMarketImage7,
      brixtonMarketImage8,
      brixtonMarketImage9,
      brixtonMarketImage10,
    ],
  },
  {
    title: "St Christopher's Hospice",
    description: "TMCO Charity visited St Christopher's Hospice for our 9th anniversary celebrations! We donated £200 and continued our yearly tradition of providing food items and basic necessities. With rising living costs and increased health challenges worldwide, many families benefit from our support.",
    cardImageBackground: stChristophersImage1,
    modalCarouselImages: [
      stChristophersImage1,
      stChristophersImage2,
    ],
  },
  {
    title: 'Sunday Feeding Program',
    description: 'Sunday feeding at our previous church building, providing meals and support to those in need.',
    cardImageBackground: sundayFeedingImage1,
    modalCarouselImages: [
      sundayFeedingImage1,
    ],
  },
  {
    title: 'Feeding the widows',
    description: 'We catered for and provided numerous meals and supplies to dozens of widows in Nigeria',
    cardImageBackground: feedingWidowsCarouselImage1,
    modalCarouselImages: [
      feedingWidowsCarouselImage1,
      feedingWidowsCarouselImage2,
      feedingWidowsCarouselImage3,
    ],
  },
  {
    title: 'Building a bridge',
    description: `In a town in Nigeria, we built a bridge which was the first in the local area, and allowed for residents 
    to travel to and fro for business and leisure purposes. The bridge is oficially opened and remains a vital piece of infrastructure for the community`,
    cardImageBackground: buildingBridgeCarouselImage1,
    modalCarouselImages: [
      buildingBridgeCarouselImage1,
      buildingBridgeCarouselImage2,
      buildingBridgeCarouselImage3,
    ],
  },
]

const bankAccountInformation = [
  {
    currency: 'Nigerian Naira',
    accountName: 'Teleola Martha Christian Organisation Orphanage Home',
    bank: 'UBA Plc',
    accountNumber: '1023249339',
  },

  {
    currency: 'United States Dollar',
    accountName: 'Teleola Martha Christian Organisation Orphanage Home',
    bank: 'UBA Plc',
    accountNumber: '3002942963',
  },
  {
    currency: 'Great British Pound',
    accountName: 'Teleola Martha Christian Organisation',
    bank: 'Barclays Bank',
    accountNumber: '1023249339',
    sortCode: '20-29-63',
    IBAN: 'GB71 BUKB 20296313341380',
  },
]
const CharityPage = () => {
  const [modalContent, setModalContent] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = (story) => {
    setModalContent(story)
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Title level={1}>Teleola Martha Christian Organisation (TMCO)</Title>

      <Title
        level={4}
        style={{ paddingBottom: 50, fontStyle: 'italic', fontWeight: 'normal' }}
      >
        We care for the unprivileged, the needy and widows. We support orphans,
        widows, and others in need.
      </Title>

      <Carousel autoplay>
        {mainCarouselImages?.map((carouselImage) => {
          return (
            <div>
              <AppCard height="500px" image={carouselImage} opacity="0" />
            </div>
          )
        })}
      </Carousel>

      <Divider />

      <Title level={2}>About Us</Title>

      <Paragraph>
        TMCO was set up in 2015 after Prophetess Teleola received a vision to
        set up a charity to support the less fortunate. TMCO has been a key
        supporter in numerous endeavours to improve the lives of widows and
        orphans, including providing food, clothes + accomodation, educational
        scholarships, free weekly breakfasts and many more activities.
      </Paragraph>

      <Title level={2}>Our Impact</Title>
      <Row gutter={16} className="card-row">
        {successStoryCardContents.map((successStoryCard, idx) => {
          return (
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div onClick={() => showModal(successStoryCard)}>
                <AppCard
                  height="200px"
                  title={successStoryCard.title}
                  image={successStoryCard.cardImageBackground}
                  opacity="0.3"
                />
              </div>

              <Modal
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                  <Button key="submit" type="primary" onClick={handleOk}>
                    OK
                  </Button>,
                ]}
              >
                <Title level={2}>{modalContent?.title}</Title>
                <Paragraph style={{ textAlign: 'center' }}>
                  {modalContent?.description}
                </Paragraph>

                <Carousel autoplay>
                  {modalContent?.modalCarouselImages?.map((image, idx) => {
                    return (
                      <div>
                        <img
                          src={image}
                          alt={`Carousel ${idx}`}
                          style={{
                            width: '100%',
                            maxHeight: '400px',
                            margin: '0 auto',
                          }}
                        />
                      </div>
                    )
                  })}
                </Carousel>
              </Modal>
            </Col>
          )
        })}
      </Row>

      <div>
        <Title level={2}>Support TMCO</Title>

        <Title level={4}>
          Your donation goes a long way, no matter how small.
        </Title>
        <Paragraph></Paragraph>

        <Card>
          <Text style={{ fontWeight: 'bold', color: 'red' }}>
            Beware of scammers. These are our only payment details.
          </Text>

          <Row gutter={16} className="card-row">
            {bankAccountInformation?.map((account) => {
              return (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Title level={4}>{account.currency}</Title>
                  <Paragraph>{account.bank}</Paragraph>

                  <Paragraph>{account.accountName}</Paragraph>

                  <Paragraph>{account.accountNumber}</Paragraph>

                  <Paragraph>{account.sortCode}</Paragraph>

                  <Paragraph>{account.IBAN}</Paragraph>
                </Col>
              )
            })}

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Title level={4}>PayPal</Title>

              <Link to="https://www.paypal.com/paypalme/tmcocharity1174523?country.x=GB&locale.x=en_GB">
                Donate Here
              </Link>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  )
}

export default CharityPage
